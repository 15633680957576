import './styles.css';
import React, { useState, useEffect } from 'react';
import { HiOutlineMoon } from 'react-icons/hi2';
import { PiSunDim } from 'react-icons/pi';
import { TfiSearch } from 'react-icons/tfi';

const Navbar = ({ busqueda, setBusqueda, mode, setMode }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar  ${isScrolled ? 'navbar-scrolled' : ''} ${
        mode ? 'color-a' : 'color-b'
      }`}
    >
      <img
        className="logo_icon"
        src={
          mode
            ? require('../assets/yespluginswhite.png')
            : require('../assets/yespluginsdark.png')
        }
        alt="imagelogo"
      />

      <div className="search-mode">
        <div
          className={`button-mode ${
            mode ? 'dark-mode-back' : 'light-mode-back'
          }`}
          onClick={() => {
            setMode(!mode);
          }}
        >
          {mode ? (
            <PiSunDim size={20} color="#6169FF" />
          ) : (
            <HiOutlineMoon size={20} color="#6169FF" />
          )}
        </div>
        <div
          className={`search-button ${
            mode ? 'dark-mode-back' : 'light-mode-back'
          }`}
        >
          <TfiSearch size={18} color="#6169FF" />
          <input
            type="text"
            placeholder="Buscar..."
            className="placeholder"
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
