import React, { useState } from 'react';
import './styles3.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Assets_data } from '../db/datos-app';
import { PiStarLight } from 'react-icons/pi';
import Modal from './Modal/index';

const settings = {
  dots: false,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  centerMode: true,
  infinite: true, // Add this line to disable the infinite scrolling
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};

export default function Carrousel({ mode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [informacion, setInformacion] = useState(null);

  const openModal = (app) => {
    setInformacion(app);
    setIsOpen(true);
  };

  return (
    <div>
      <div className="componentList-c">
        {/* Aqui va la lista  */}
        <Slider {...settings} arrows={false} className="custom-slider">
          {Assets_data.filter((app) => app.rating >= 4.9).map((app, index) => (
            <div
              className="element-list-c"
              key={index}
              onClick={() => openModal(app)}
            >
              <div className="info-apps">
                <img src={app.icon} alt="icon-app" className="image-app-c" />
                <div className="base-info">
                  <div className="title-rating">
                    <p className="title">{app.name}</p>
                    <div className="rating">
                      <PiStarLight size={14} color="#FFFF" />
                      <p className="rating-text">{app.rating.toFixed(1)}</p>
                    </div>
                  </div>
                  <div className="prueba">
                    <p className="description-text">{app.description}</p>
                  </div>
                </div>
              </div>
              <div
                className="date-app-c"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), black), url(${
                    app.cover ? app.cover : app.icon
                  })`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  filter: app.cover ? null : 'blur(5px)',
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <Modal
        show={isOpen}
        closeModal={() => {
          setIsOpen(false);
        }}
        row={informacion}
        mode={mode}
      />
    </div>
  );
}
