import React, { useState } from 'react';
import './styles4.css';
import Modal from './Modal/index';
import { Assets_data } from '../db/datos-app';
import { PiStarLight, PiDownloadLight } from 'react-icons/pi';
import { PiPlayBold } from 'react-icons/pi';
import { IoClose } from 'react-icons/io5';

export default function Busqueda({ busqueda, setBusqueda, mode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [informacion, setInformacion] = useState(null);

  const openModal = (app) => {
    setInformacion(app);
    setIsOpen(true);
  };

  const DatosFiltrados = Assets_data.filter((dato) =>
    dato.name.toLowerCase().includes(busqueda.toLowerCase())
  );

  return (
    <div className="contenedor-maestro">
      <div className="componentList">
        <div className="header">
          <div
            className="close"
            onClick={() => {
              setBusqueda(null);
            }}
          >
            <IoClose size={18} color="#ffff" />
          </div>
          <p className="text">
            Search Results for: <p className="textBusqueda">{busqueda}</p>
          </p>
        </div>
        <div className="divider"></div>
        {/* Aqui va la lista  */}
        {DatosFiltrados.map((app, index) => (
          <div
            className="element-list"
            key={index}
            onClick={() => openModal(app)}
          >
            <img src={app.icon} alt="icon-app" className="image-app" />
            <div className="info-app">
              <p className="title-app">{app.name}</p>
              <div className="stats">
                <div className="spected">
                  <PiDownloadLight size={18} color="#6169FF" />
                  <p className="tag">Size:</p>
                  <p className="data-info">{app.size}</p>
                </div>
                <div className="spected2">
                  <PiStarLight size={18} color="#6169FF" />
                  <p className="tag">Rating:</p>
                  <p className="data-info">{app.rating}</p>
                </div>
              </div>
            </div>
            <div className="button-go">
              <div className="style-button">
                <PiPlayBold size={18} color="#ffff" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        show={isOpen}
        closeModal={() => {
          setIsOpen(false);
        }}
        row={informacion}
        mode={mode}
      />
    </div>
  );
}
