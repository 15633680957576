import React, { useState } from 'react';
import './styles2.css';
import { Assets_data } from '../db/datos-app';
import { VscGame } from 'react-icons/vsc';
import { RxMobile } from 'react-icons/rx';
import Modal from './Modal/index';

export default function Width({ mode, setOpenModal }) {
  const [isOpen, setIsOpen] = useState(false);
  const [informacion, setInformacion] = useState(null);

  const openModal = (app) => {
    setInformacion(app);
    setIsOpen(true);
  };
  return (
    <div>
      <div className="componentList">
        <p className="font-title">Trending</p>
        <div className={mode ? 'scroll-xx' : 'scroll-x'}>
          {/* Aqui va la lista  */}
          {Assets_data.filter((app) => app.rating >= 4.9).map((app, index) => (
            <div
              className={
                mode ? 'element-list-w color-a' : 'element-list-w color-b'
              }
              key={index}
              onClick={() => openModal(app)}
              // onClick={() => setMode(!mode)}
            >
              <img src={app.icon} alt="icon-app" className="image-app-w" />
              <div className="info-app">
                <p className="title-app-w">{app.name}</p>
              </div>
              <div className="info-app-w">
                {app.category === 'Apps' ? (
                  <RxMobile size={18} color="#6169FF" />
                ) : (
                  <VscGame size={18} color="#6169FF" />
                )}
                <p className="data-info-w">{app.category}</p>
              </div>
            </div>
          ))}
        </div>
        <Modal
          show={isOpen}
          closeModal={() => {
            setIsOpen(false);
          }}
          row={informacion}
          mode={mode}
          setOpenModal={setOpenModal}
        />
      </div>
    </div>
  );
}
