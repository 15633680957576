import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './layout/navbar';
import Main from './views/main';
import Width from './views/width';
import Carrousel from './views/carrousel';
import Busqueda from './views/busqueda';

function App() {
  const [busqueda, setBusqueda] = useState('');
  const [mode, setMode] = useState(false);

  return (
    <div className="App">
      <header className={`App-header ${mode ? 'color-a' : 'color-b'}`}>
        <Navbar
          busqueda={busqueda}
          setBusqueda={setBusqueda}
          mode={mode}
          setMode={setMode}
        />
        <div
          className={`back-component ${
            mode ? 'dark-mode-back' : 'light-mode-back'
          }`}
        >
          {busqueda && busqueda !== '' ? (
            <Busqueda
              busqueda={busqueda}
              setBusqueda={setBusqueda}
              mode={mode}
            />
          ) : (
            <>
              <Carrousel mode={mode} />
              <Width mode={mode} />
              <Main mode={mode} />
            </>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
