import React, { useState } from 'react';
import './styles.css';
import { Assets_data } from '../db/datos-app';
import { PiStarLight, PiDownloadLight } from 'react-icons/pi';
import { PiPlayBold } from 'react-icons/pi';
import Modal from './Modal/index';

export default function Main({ mode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [informacion, setInformacion] = useState(null);

  const openModal = (app) => {
    setInformacion(app);
    setIsOpen(true);
  };

  return (
    <div>
      <div className="componentList">
        <p className="font-title">Games</p>

        {/* Aqui va la lista  */}
        {Assets_data.filter(
          (app) => app.category === 'Games' && app.rating >= 4.9
        ).map((app, index) => (
          <div
            className={`element-list ${mode ? 'color-a' : 'color-b'}`}
            key={index}
            onClick={() => openModal(app)}
          >
            <img src={app.icon} alt="icon-app" className="image-app" />
            <div className="info-app">
              <p className="title-app">{app.name}</p>
              <div className="stats">
                <div className="spected">
                  <PiDownloadLight size={18} color="#6169FF" />
                  <p className="tag">Size:</p>
                  <p className="data-info">{app.size}</p>
                </div>

                <div className="spected2">
                  <PiStarLight size={18} color="#6169FF" />
                  <p className="tag">Rating:</p>
                  <p className="data-info">{app.rating}</p>
                </div>
              </div>
            </div>
            <div className="button-go">
              <div className="style-button">
                <PiPlayBold size={18} color="#ffff" />
              </div>
            </div>
          </div>
        ))}

        <p className="font-title">Apps</p>
        {/* Aqui va la lista  */}
        {Assets_data.filter((app) => app.category === 'Apps').map(
          (app, index) => (
            <div
              className={`element-list ${mode ? 'color-a' : 'color-b'}`}
              key={index}
              onClick={() => openModal(app)}
            >
              <img src={app.icon} alt="icon-app" className="image-app" />
              <div className="info-app">
                <p className="title-app">{app.name}</p>
                <div className="stats">
                  <div className="spected">
                    <PiDownloadLight size={18} color="#6169FF" />
                    <p className="tag">Size:</p>
                    <p className="data-info">{app.size}</p>
                  </div>

                  <div className="spected2">
                    <PiStarLight size={18} color="#6169FF" />
                    <p className="tag">Rating:</p>
                    <p className="data-info">{app.rating}</p>
                  </div>
                </div>
              </div>
              <div className="button-go">
                <div className="style-button">
                  <PiPlayBold size={18} color="#ffff" />
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <Modal
        show={isOpen}
        closeModal={() => {
          setIsOpen(false);
        }}
        row={informacion}
        mode={mode}
      />
    </div>
  );
}
