import React, { useState } from 'react';
import { IoLogoAndroid } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { PiDownloadSimpleBold } from 'react-icons/pi';
import { PiStarLight } from 'react-icons/pi';
import { PiDownloadLight, PiUserSquareLight } from 'react-icons/pi';
import { BsHddStack } from 'react-icons/bs';
import { CgSmartphoneChip } from 'react-icons/cg';
import Modal_Loading from '../Modal_loading';

import './ModalBack.css'; // Asegúrate de tener un archivo Modal.css en la misma carpeta
import { FaApple } from 'react-icons/fa';
import { Divider } from '@mui/material';

const Modal = ({ show, closeModal, row, mode }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="totally">
      {show && (
        <div className="modal">
          <div className="modal-content">
            <div
              className="app-cover"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), black), url(${
                  row.cover ? row.cover : row.icon
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                filter: row.cover ? null : 'blur(5px)',
              }}
            />
            <div className="button-contener">
              <IoClose
                onClick={closeModal}
                className="Button-exit"
                fill="#ffff"
              />
            </div>

            <div className={`component ${mode ? 'color-a' : 'color-b'}`}>
              <div className="tittle_app">
                <div className="cabecera_modal">
                  <img
                    src={row.icon}
                    alt={row.name}
                    className="app-image"
                    style={{
                      width: '90px',
                      borderRadius: 25,
                    }}
                  />
                </div>
                <div className="data_top">
                  <div className="data_left">
                    <div className="dot-plus">
                      <p>PLUGIN</p>
                    </div>
                    <p className="text-title-c">{row.name}</p>
                  </div>
                  <div
                    className="data_right"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <PiDownloadSimpleBold className="app-icon" fill="#ffff" />
                  </div>
                </div>
              </div>
              <Divider />
              <div className={mode ? 'box-data-d' : 'box-data'}>
                <div className="contener">
                  <PiStarLight className="app-icons" fill="#6169ff" />
                  <h3 className="endurance">Rating:</h3>
                  <h4 className="local-text">{row.rating}</h4>
                </div>
                <div className="contener">
                  <BsHddStack className="app-icons" fill="#6169ff" />
                  <h3 className="endurance">Size:</h3>
                  <h4 className="local-text">{row.size}</h4>
                </div>
                <div className="contener">
                  <PiDownloadLight className="app-icons" fill="#6169ff" />
                  <h3 className="endurance">Downloads:</h3>
                  <h4 className="local-text">{row.downloads}</h4>
                </div>
                <div className="contener">
                  <PiUserSquareLight className="app-icons" fill="#6169ff" />
                  <h3 className="endurance">Developer:</h3>
                  <h4 className="local-text">{row.developer}</h4>
                </div>
                <div className="contener">
                  <PiUserSquareLight className="app-icons" fill="#6169ff" />
                  <h3 className="endurance">Category:</h3>
                  <h4 className="local-text">{row.category}</h4>
                </div>
                <div className="contener">
                  <PiUserSquareLight className="app-icons" fill="#6169ff" />
                  <h3 className="endurance">Developer:</h3>
                  <h4 className="local-text">{row.developer}</h4>
                </div>
                <div className="contener-2">
                  <CgSmartphoneChip className="app-icons" fill="#6169ff" />
                  <h3 className="endurance">Platform:</h3>
                  <div>
                    <IoLogoAndroid className="app-icon" fill="#a6b0cf" />
                    <FaApple className="app-icon" fill="#a6b0cf" />
                  </div>
                </div>
              </div>
              <div className="description-tex-2">
                <h4 className="local-text-2">{row.description}</h4>
              </div>
            </div>
          </div>
          <Modal_Loading openModal={openModal} row={row} mode={mode} />
        </div>
      )}
    </div>
  );
};

export default Modal;
